import React from "react"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import SEO from "../components/seo"
import ButtonB from "../components/ButtonB"
import "../styles/charity.scss"

//Images

const charity = () => {
  return (
    <Layout>
      <SEO 
      title="Charity" 
      description=""
      keywords= {["","",""]}
      lang = "en-gb"
      />
      <ScrollArrow showBelow={550} />
      {/****** Hero *******/}
      <div className="charity-hero-section">
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Charity</h1>
                  <h2>Giving back to the global community</h2>
                  {/* <ButtonB href="#charity-cards">See How</ButtonB> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/****** Cards *******/}
      <div className="secton" id="charity-cards">
        <div className="outer-container">
          <div className="inner-container">
            <h2>Making a difference</h2>
            <h3>Metsi gives back to the global community</h3>
            <p>
            At Metsi, we invest in our local communities, through charitable events 
            that support healthy families, healthy children, and advances in medical research.
            </p>
          </div>
        </div>
        <div className="cards">
          <div className="row">
            <a
              href="https://cyclelivenottingham.co.uk/charity/"
              target="_blank"
              className="card"
              id="cycle"
            >
              <div className="overlay-left">
                <div className="card-text">
                  <h4>
                    Cycle Live Nottingham - <br />
                    Riding for Nottingham Childrens Hospitals
                  </h4>
                  <h5>UK 2019</h5>
                </div>
              </div>
            </a>
            <a
              className="card"
              id="love"
              href="https://ratrace.com/"
              target="_blank"
            >
              <div className="overlay-right">
                <div className="card-text">
                  <h4>
                  RatRace Runstock - <br />
                  Children with Cancer
                  </h4>
                  <h5>USA 2018</h5>
                </div>
              </div>
            </a>
          </div>
          <div className="row">
            <a
              className="card"
              id="ride"
              href="https://www.parkinsons.org.uk/events/prudential-ridelondon-surrey-100"
              target="_blank"
            >
              <div className="overlay-left">
                <div className="card-text">
                  <h4>
                    Ride London for Parkinson’s UK <br />
                    45 miles
                  </h4>
                  <h5>UK 2018</h5>
                </div>
              </div>
            </a>
            <a
              href="https://www.marchofdimes.org/index.aspx"
              target="_blank"
              className="card"
              id="march"
            >
              <div className="overlay-right">
                <div className="card-text">
                  <h4>
                    March of Dimes <br />
                    for Healthy Moms & Strong Babies
                  </h4>
                  <h5>USA 2018</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default charity
