import React from "react"
import "../styles/ButtonB.scss"

const ButtonB = ({ children, onClick, href, pulser }) => {
  return (
    <a
      href={href}
      className={pulser ? "btn btn-1 pulser" : "btn btn-1"}
      onClick={onClick}
    >
      <svg>
        <rect x="1" y="1" rx="5" ry="5" fill="none" width="99%" height="95%" />
      </svg>
      {children}
    </a>
  )
}

export default ButtonB
